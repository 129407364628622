export class Contact {

    constructor(
        public name: String,
        public email: String,
        public message: String,
        public CSD: boolean,
        public CELSE: boolean,
        public SI: boolean,
        public AESS: boolean,
        public MVP: boolean,
        public AVSA: boolean
    ) { }
}
