import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
  }
  scroll = (event): void => {

    var WINDOW_HEIGHT = window.innerHeight || document.documentElement.clientHeight;
    var centerPoint = (document.body.scrollHeight / 2) + (document.body.scrollHeight / 5);
    /*Image Animation */
    if (event.srcElement.scrollingElement.scrollTop > ((centerPoint - WINDOW_HEIGHT))) {
      var leftToRighttagList = document.getElementsByClassName("LeftToRightClass");
      var tagList = document.getElementsByClassName("RightToLeftClass");
      for (var i = 0; i <= leftToRighttagList.length; i++) {
        leftToRighttagList[i]?.classList?.add("bottomTotop");
      }
      for (var i = 0; i <= tagList.length; i++) {
        tagList[i]?.classList?.add("LeftToRightAnimation");
      }
    } else {
      var leftToRighttagList = document.getElementsByClassName("LeftToRightClass");
      var tagList = document.getElementsByClassName("RightToLeftClass");
      for (var i = 0; i <= leftToRighttagList.length; i++) {
        leftToRighttagList[i]?.classList?.remove("bottomTotop");
      }
      for (var i = 0; i <= tagList.length; i++) {
        tagList[i]?.classList?.remove("LeftToRightAnimation");
      }
    }
  };
}
