import { Component, OnInit } from '@angular/core';
import { Contact } from '../Models/contact'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  emailLink: string = "https://script.google.com/macros/s/AKfycbw5TClnOjTtxZcP6usob_sJDwgTlciIzlB5-Ye6BqGNwYzzn3pbL7f6dArDatv-0Qw2/exec";
  contact = new Contact('', '', '', false, false, false, false, false, false);

  constructor(private httpClient: HttpClient) { }

  resetContactForm() {
    this.contact = new Contact('', '', '', false, false, false, false, false, false);
  }

  ngOnInit(): void {
    this.IsCheckBackgroundClass();
    this.resetContactForm();
  }
  ValidateForm(): boolean {
    if (this.contact.email === null || this.contact.email === undefined || this.contact.email === "") {
      alert("Please Enter Email Address.");
      return false;
    } else {
      return true;
    }
  }

  onSubmit(data) {
      
    this.httpClient.post('https://script.google.com/macros/s/AKfycbzlzkZBFE9VGfxg3DDp0zrZwWZNOFVcucliTG_j8Hna7HEbWd1A/exec',  JSON.stringify(data))
      .subscribe((result) => {
        console.warn();
      });

  }


  SendEmail(): Observable<any> {
    if (this.ValidateForm()) {
      console.log(this.contact);


      const urlEncodeData = 'name="qasim"&message="MEsage"&email="CC@gmail.com"';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

      return this.httpClient.post('https://script.google.com/macros/s/AKfycbzlzkZBFE9VGfxg3DDp0zrZwWZNOFVcucliTG_j8Hna7HEbWd1A/exec',
        urlEncodeData, {
        headers,
        responseType: 'text'
      }).pipe(catchError((error) => {
        console.log(error)
        return EMPTY;
      }));

      // const obs$ = ajax({
      //   url: 'https://httpbin.org/delay/2',
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'rxjs-custome-header': 'Angular Army'
      //   },
      //   body: {
      //     rxjs: 'Hello World!'
      //   }
      // }).pipe(tap(userResponse => console.log('response is ', userResponse)));
      // obs$.subscribe();

    }
  }

  IsCheckBackgroundClass(): void {
    document.addEventListener("click", function () {
      var checkBoxList = document.getElementsByClassName('checkBox');
      if (checkBoxList.length > 0) {
        for (var i = 0; i < checkBoxList.length; i++) {
          var isCheck = document.getElementById(`${checkBoxList[i].id}`) as HTMLInputElement;
          if (isCheck.checked) {
            if (isCheck.parentElement.parentElement.classList.contains('inputBackgroundColor') === false) {
              isCheck.parentElement.parentElement.classList.add('inputBackgroundColor');
            }
          } else {
            isCheck.parentElement.parentElement.classList.remove('inputBackgroundColor');
          }
        }
      }
    });
  }

}
