<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="form">
                <form ngNoForm class="gform" method="POST" name="contactForm"
                    action="https://script.google.com/macros/s/AKfycbzlzkZBFE9VGfxg3DDp0zrZwWZNOFVcucliTG_j8Hna7HEbWd1A/exec">
                    <div class="col-lg-12">
                        <div class="col-lg-12 form-Heading">
                            <span>Now, about your</span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="col-lg-12">
                            <span class="nextversion"><img src="../../../assets/images/footer-nextVersion.svg"
                                    alt="Logo" /></span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="form_name">What is your full name?</label>
                                <input id="form_name" type="text" [(ngModel)]="contact.name" name="name"
                                    class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="form_Email">What is your email address?</label>
                                <input id="form_Email" type="email" [(ngModel)]="contact.email" name="email"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="col-lg-12 form-para">
                            <label>What kind of services are you looking for? (select one or more) <label
                                    style="color: red;">*</label></label>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="col-lg-6">
                            <div class="form-input">
                                <div class="round">
                                    <input type="checkbox" class="checkBox" id="checkbox1" [(ngModel)]="contact.CSD"
                                        name="CSD" />
                                    <label for="checkbox1"></label>
                                    <img src="../../../assets/images/Contact-icons/box.svg" alt="Logo" />
                                    <span>Cloud Migration</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-input">
                                <div class="round">
                                    <input type="checkbox" class="checkBox" id="checkbox2" [(ngModel)]="contact.CELSE"
                                        name="CELSE" />
                                    <label for="checkbox2"></label>
                                    <img src="../../../assets/images/Contact-icons/activity.svg" alt="Logo" />
                                    <span>DevOps</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="col-lg-6">
                            <div class="form-input">
                                <div class="round">
                                    <input type="checkbox" class="checkBox" id="checkbox3" [(ngModel)]="contact.SI"
                                        name="SI" />
                                    <label for="checkbox3"></label>
                                    <img src="../../../assets/images/Contact-icons/command.svg" alt="Logo" />
                                    <span>System Integration(SI)</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-input">
                                <div class="round">
                                    <input type="checkbox" class="checkBox" id="checkbox4" [(ngModel)]="contact.AESS"
                                        name="AESS" />
                                    <label for="checkbox4"></label>
                                    <img src="../../../assets/images/Contact-icons/airplay.svg" alt="Logo" />
                                    <span>Application Modernization</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="col-lg-6">
                            <div class="form-input">
                                <div class="round">
                                    <input type="checkbox" class="checkBox" id="checkbox5" [(ngModel)]="contact.MVP"
                                        name="MVP" />
                                    <label for="checkbox5"></label>
                                    <img src="../../../assets/images/Contact-icons/cpu.svg" alt="Logo" />
                                    <span>Data & Analytics</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-input">
                                <div class="round">
                                    <input type="checkbox" class="checkBox" id="checkbox6" [(ngModel)]="contact.AVSA"
                                        name="AVSA" />
                                    <label for="checkbox6"></label>
                                    <img src="../../../assets/images/Contact-icons/anchor.svg" alt="Logo" />
                                    <span>Managed Services</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="col-lg-12 form-para">
                            <label>Tell us what you need help with, or anything else that you would like to
                                share</label>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="col-lg-12">
                            <textarea class="form-message" type="text" [(ngModel)]="contact.message" name="message"
                                placeholder="Hello NextVersion Team, I’d love to talk to you about a healthcare project in an early stage..."></textarea>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <button id="Contactbtnsubmit" type="submit" class="pull-right submit-Btn">Submit</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>