<div class="box-space-top box-space-bottom">
    <div class="container">
        <div class="row" style="height: 840px;">
            <div class="row rectangle5 text-center">
                <div class="col-lg-12">
                    <span class="our text-center">Your</span>
                </div>
                <div class="col-lg-12">
                    <span class="nextversion"><img src="../../../assets/images/NEXTVERSION.svg" alt="Logo" /></span>
                </div>
                <div class="col-lg-12">
                    <p class="handOnexpertise">
                        Hands on expertise in implementing superior quality healthcare services without compromising
                        sensitive patient data on the cloud.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!--What We Can DO-->
    <div class="container">
        <div class="row whatwedo">
            <div class="whatwedocontent justify-content-center align-items-center">
                <div class="row text-center" style="margin-top: 12%;">
                    <div class="col-lg-12">
                        <span class="whatWeDoHeading">Who we are & What we do</span>
                    </div>
                    <div class="col-lg-12" style="text-align: initial;">
                        <p class="whatWeDoDisc">Next Version provides a complete range of cloud consulting services to
                            healthcare organizations.
                            We design and implement multi-cloud solutions to meet the challenges hospitals and
                            healthcare providers face today.
                            We work with many types of healthcare entities, from large health systems to fast-growing
                            startups. Our technical and operating team have expertise in healthcare IT roles at
                            hospitals, healthcare organizations, and technology providers.
                            <br><br/><br/><span class="whatWeDoHeading" style="font-weight: bold;">We speak your language.</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!---->
    <div class="container">
        <div class="row text-center ServiceHeight">
            <div class="col-lg-12" style="margin-bottom: 5%;">
                <span class="ourservicesheading text-center">Our Services</span>
            </div>
            <div class="col-lg-12">
                <div class="col-lg-4">
                    <div class="frame flip-card">
                        <img src="../../../assets/images/box.svg">
                        <span class="">
                            Cloud Migration
                        </span>
                        <p>Migrate legacy applications and infrastructure to the cloud without disrupting operations or
                            losing critical PHI in the process.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="frame flip-card">
                        <img src="../../../assets/images/activity.svg">
                        <span class="">
                            DevOps
                        </span>
                        <p>Leverage DevOps to automate your CI/CD pipelines and deliver updates quickly for your
                            mission-critical healthcare apps. </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="frame flip-card">
                        <img src="../../../assets/images/airplay.svg">
                        <span class="">
                            Application Modernization
                        </span>
                        <p>Modernize your applications for the cloud by taking advantage of containers, serverless
                            computing, microservices, and other cloud-native technologies.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="col-lg-4">
                    <div class="frame flip-card">
                        <img src="../../../assets/images/Vector.svg">
                        <span class="">
                            System Integration(SI)
                        </span>
                        <p>We can help you integrate anything and everything. Transform your APIs, 3rd party
                            integrations, and microservices to overcome the challenges of system interoperability.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="frame flip-card">
                        <img src="../../../assets/images/cpu.svg">
                        <span class="">
                            Data & Analytics
                        </span>
                        <p>Migrate and configure scalable cloud native data lakes, pipelines, and analytical
                            infrastructure capable of handling massive data volumes.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="frame flip-card">
                        <img src="../../../assets/images/anchor.svg">
                        <span class="">
                            Managed Services
                        </span>
                        <p>Reduce IT costs and improve productivity with 24/7 cloud support and monitoring, and managed
                            DevOps operations. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Other Industries -->

    <div class="container ">
        <div class="row text-center">
            <div class="col-lg-12" style="margin-bottom: 3%;">
                <span class="ourservicesheading text-center">Solutions</span>
            </div>
            <div>
                <p style="text-align: center;font-size: 18px;">
                    We build scalable, real-time, secure healthcare integrations on the cloud
                </p>
            </div>
            <div class="row" style="margin-top: 5rem;">
                <div class="col-lg-2">
                    <img src="../../../assets/images/JourneyLogo.svg" style="width: 50%;margin-top: -0.5rem;">
                </div>
                <div class="col-lg-3">
                    <img src="../../../assets/images/cerner.png" style="width: 100%;">
                </div>
                <div class="col-lg-3">
                    <img src="../../../assets/images/sunnybroke.png" style="width: 100%;">
                </div>
                <div class="col-lg-3">
                    <img src="../../../assets/images/meditech.png" style="width: 100%; margin-top: 2rem;">
                </div>
            </div>
            <div class="row" style="margin-top: 5rem;">
                <div class="col-lg-2">
                    <img src="../../../assets/images/northYark.png" style="width: 100%;">
                </div>
                <div class="col-lg-2">
                    <img src="../../../assets/images/epic.png" style="width: 100%;">
                </div>
                <div class="col-lg-3">
                    <img src="../../../assets/images/azure.png" style="width: 100%;margin-top: -3rem;">
                </div>
                <div class="col-lg-3">
                    <img src="../../../assets/images/googleCloud.png" style="width: 100%;margin-top: -3rem;">
                </div>
                <div class="col-lg-2">
                    <img src="../../../assets/images/ontario.png" style="width: 100%; margin-top: 1rem;">
                </div>
            </div>


            <!-- <div class="col-lg-12">
                <div class="col-lg-6">
                    <div class="group trans">
                        <img src="../../../assets/images/dollar-sign.svg">
                        <span>FinTech</span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="group trans">
                        <img src="../../../assets/images/book-open.svg">
                        <span>Education</span>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-lg-12">
                <div class="col-lg-6">
                    <div class="group trans">
                        <img src="../../../assets/images/tv.svg">
                        <span>Media & Entertainment</span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="group trans">
                        <img src="../../../assets/images/shopping-bag.svg">
                        <span>Retail / E-Commerce</span>
                    </div>
                </div>
            </div> -->
        </div>



    </div>
    <!---->

    <!--  -->
    <div class="container-fluid">
        <div class="col-lg-4" id="LeftImage"></div>
        <div class="container">
            <div class="col-lg-12">
                <div class="row text-center">
                    <div class="col-lg-12" style="margin-bottom: 5%;margin-top: 15%;">
                        <span class="partnerHeading">Why Choose Next Version as your Cloud Solution Provider?</span>
                        <p class="partnerParagraph" style="text-align: center;margin-top: 2rem;">Next Version cloud solutions offer several benefits, making it an excellent choice for healthcare organizations.</p>
                    </div>
                    <div class="col-lg-12 ">
                        <div class="LeftToRightClass bottomTotop">
                            <span class="partner-subHeading">Enhanced Security</span>
                            <p class="partnerParagraph">We employ advanced security measures in our solutions to protect <br/>sensitive patient data and ensure compliance with PIPEDA and HIPAA regulations.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="RightToLeftClass bottomTotop">
                            <span class="partner-subHeading">Scalability</span>
                            <p class="partnerParagraph">The cloud solutions we offer are designed to be flexible and scalable to meet the evolving needs of your healthcare organization, enabling you to expand or reduce capacity as needed.</p>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="LeftToRightClass bottomTotop">
                            <span class="partner-subHeading">Increased Efficiency</span>
                            <p class="partnerParagraph">The solutions provided streamline operations, automate tasks, and minimize paperwork, enabling staff to concentrate on providing exceptional patient care.</p>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="RightToLeftClass bottomTotop">
                            <span class="partner-subHeading">Improved Patient Experience</span>
                            <p class="partnerParagraph">Our solutions enable patients to conveniently access their medical records, schedule appointments, and receive telemedicine services, enhancing their overall healthcare experience.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="LeftToRightClass bottomTotop">
                            <span class="partner-subHeading">Customizable Solutions</span>
                            <p class="partnerParagraph">We can tailor our solutions to meet the specific requirements of your healthcare organization, ensuring they align with your business goals and objectives.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="LeftToRightClass bottomTotop">
                            <span class="partner-subHeading">Expert Support</span>
                            <p class="partnerParagraph">We offer expert support to ensure your healthcare organization receives the necessary guidance and assistance to optimize cloud performance and successfully achieve your goals.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-1" id="RightImage"></div>
    </div>
    <!---->
</div>